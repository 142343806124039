import { useState, useMemo, useEffect, useRef } from "react";
import styled from "styled-components";
import { ApplicationsList } from "../components/ApplicationsList";
import { Button } from "../components/Button";
import { Flex } from "../components/Flex";
import { Input } from "../components/Input";
import Topbar from "../components/Topbar";
import { FaPlus } from "react-icons/fa";
import { MdTune } from "react-icons/md";
import { Filters } from "../components/Filters";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";
import { useMandateStatuses } from "../context/MandateStatusContext";
import { capitalise } from "../utils/string";
import { Loader } from "../components/Loader";
import { UserFilter } from "../components/UserFilter";
import { useUsers } from "../data/useUsers";
import { env } from "../utils/env";
import { Box } from "../components/Box";
import { H3 } from "../components/Heading";
import { ApplicationsTable } from "../components/ApplicationsTable";
import kanban from "../assets/kanban-view.svg";
import grid from "../assets/grid-view.svg";
import { Image } from "../components/Image";
import { useBanner } from "../data/useBanner";
import BannerComponent from "../components/Banner";
import CustomTabs from "../components/CustomTabs";

export const Columns = styled.div<any>`
  ${({ theme: { space }, submittedSearchTerm }) => `
    display: flex;
    gap: ${space[2]};
    padding: ${space[4]};);
    overflow: auto;
  `}
`;

const initialState = {
  status: "all",
  consultant: null,
  dateStarted: [null, null],
  applicationType: null,
  noOfIndividuals: null,
  includeArchived: false,
};

const Applications = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const { statuses, isLoadingStatuses } = useMandateStatuses();
  const { data: bannerData, isLoading: isBannerLoading } = useBanner(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [submittedSearchTerm, setSubmittedSearchTerm] = useState("");
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filters, setFilters] = useState(initialState);
  const { data: users } = useUsers();
  const isLoading = isLoadingStatuses;

  const [isBannerVisible, setIsBannerVisible] = useState(true);

  const isTableView = activeTab === 1;

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    const dismissed = localStorage.getItem("bannerDismissed");
    if (dismissed || bannerData?.length === 0) {
      setIsBannerVisible(false);
    }

    // Listen for storage change in other tabs/windows
    const handleStorageChange = (event) => {
      if (event.key === "bannerDismissed" && event.newValue === "true") {
        setIsBannerVisible(false);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [bannerData]);

  const [bannerHeight, setBannerHeight] = useState(0);
  const bannerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bannerRef.current) {
      setBannerHeight(bannerRef.current.offsetHeight);
    }
  }, [bannerRef.current, bannerData, isBannerVisible]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--banner-height",
      `${isBannerVisible ? bannerHeight : 0}px`
    );
  }, [bannerHeight, isBannerVisible]);

  const handleDismiss = () => {
    localStorage.setItem("bannerDismissed", "true");
    setIsBannerVisible(false);
  };

  // Reset banner only when a new window is opened
  useEffect(() => {
    const isNewWindow = !sessionStorage.getItem("windowOpened");
    if (isNewWindow) {
      localStorage.removeItem("bannerDismissed"); // Reset on new window
      sessionStorage.setItem("windowOpened", "true"); // Mark window as opened
    }
  }, []);

  const FilteredBanner =
    bannerData &&
    bannerData.length > 0 &&
    bannerData.reduceRight((acc, b) => {
      if (!acc && b.status === true) {
        return b;
      }
      return acc;
    }, null);

  const userOptions = users?.data?.results.map(
    ({ first_name, last_name, id, colour }) => ({
      value: id,
      label: `${first_name} ${last_name}`,
      firstName: first_name,
      lastName: last_name,
      colour,
    })
  );

  const filterUserIds = useMemo(
    () => [selectedUser?.value],
    [selectedUser?.value]
  );

  const handleFilterSubmit = (values) => {
    setFilters(values);
    setIsFiltersOpen(false);
  };

  const handleSearchSubmit = () => {
    setSubmittedSearchTerm(searchTerm);
  };

  const handleResetSearch = () => {
    setSearchTerm("");
    setSubmittedSearchTerm("");
  };

  const tableViewContent = (
    <>
      <div className="p-2">
        <ApplicationsTable
          searchTerm={submittedSearchTerm}
          filterUserIds={filterUserIds}
          filters={filters}
          bannerHeight={bannerHeight}
          isBannerVisible={isBannerVisible}
        ></ApplicationsTable>
      </div>
    </>
  );

  const cardViewContent = (
    <>
      {!isLoading && statuses && (
        <Columns
          submittedSearchTerm={submittedSearchTerm}
          style={{
            height: isBannerVisible
              ? bannerHeight
                ? submittedSearchTerm
                  ? "calc(100vh - (305px + 330px))"
                  : "calc(100vh - (250px + 330px))"
                : submittedSearchTerm
                ? "calc(100vh - (305px + 150px))"
                : "calc(100vh - (250px + 300px))"
              : submittedSearchTerm
              ? "calc(100vh - 305px)"
              : "calc(100vh - 250px)",
          }}
        >
          {statuses?.data.map((status) => (
            <ApplicationsList
              key={status.id}
              statusId={status.id}
              name={capitalise(status.status)}
              statusName={status.status}
              searchTerm={submittedSearchTerm}
              filterUserIds={filterUserIds}
              filters={filters}
            />
          ))}
        </Columns>
      )}
    </>
  );

  return (
    <div>
      <Topbar title="Applications">
        <Flex justifyContent="space-between">
          {env.isSearchApplicationsFeatureOn ? (
            <Flex ml={4}>
              <Input
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
                type="search"
                size="small"
                placeholder="Search"
                maxWidth={250}
                mr={1}
              />
              <Button
                isDisabled={submittedSearchTerm === searchTerm}
                onClick={handleSearchSubmit}
              >
                Search
              </Button>

              {submittedSearchTerm && (
                <Button
                  variant="text"
                  pl="0px"
                  ml={1}
                  onClick={handleResetSearch}
                >
                  Reset search
                </Button>
              )}
            </Flex>
          ) : (
            <Box />
          )}
          <Flex>
            <UserFilter
              size="regular"
              options={userOptions}
              value={selectedUser}
              onChange={setSelectedUser as any}
              mr={1}
            />
            {env.isFilterApplicationsFeatureOn && (
              <Button
                variant="secondary"
                mr={1}
                Icon={MdTune}
                iconPosition="right"
                onClick={() => setIsFiltersOpen((isOpen) => !isOpen)}
              >
                Filter
              </Button>
            )}
            <Button
              Icon={FaPlus}
              iconSize="16px"
              onClick={() => navigate(ROUTE.APPLICATION_NEW)}
            >
              New Application
            </Button>
          </Flex>
        </Flex>
      </Topbar>

      <div>
        {isLoading && (
          <Flex justifyContent="center" alignItems="center" minHeight="25vh">
            <Loader />
          </Flex>
        )}
        {submittedSearchTerm && (
          <H3 m={4} mb="0px" color="gray.60">
            Showing applications for search term: "{submittedSearchTerm}"
          </H3>
        )}

        {!isBannerLoading &&
          bannerData &&
          bannerData.length > 0 &&
          isBannerVisible &&
          FilteredBanner && (
            <BannerComponent
              ref={bannerRef}
              banner={FilteredBanner}
              onDismiss={handleDismiss}
            />
          )}

        <CustomTabs
          tabs={[
            {
              label: (
                <>
                  <Image src={kanban} alt="kanban" mr={1} />
                  Card View
                </>
              ),
              content: cardViewContent,
            },
            {
              label: (
                <>
                  <Image src={grid} alt="grid" mr={1} color="red" />
                  Table View
                </>
              ),
              content: tableViewContent,
            },
          ]}
          activeTab={activeTab}
          onTabChange={handleTabChange}
        />

        <Filters
          isOpen={isFiltersOpen}
          onRequestClose={() => setIsFiltersOpen(false)}
          onSubmit={handleFilterSubmit}
          filters={filters}
          isTableView={isTableView}
        />
      </div>
      <Filters
        isOpen={isFiltersOpen}
        onRequestClose={() => setIsFiltersOpen(false)}
        onSubmit={handleFilterSubmit}
        filters={filters}
        isTableView={isTableView}
      />
    </div>
  );
};

export default Applications;
